.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 150px;
}

.table-filter .anticon {
  color: white !important;
}

.ant-table-filter-trigger:hover {
  background-color: unset !important;
}
