 .ant-input-number-input {
   font-size: 1rem !important;
   font-style: normal;
   font-weight: 400;
   height: 3rem;
 }

 .ant-form-item-explain-error {
   font-size: 0.8rem !important;
 }



 .ant-form-item {
   margin-bottom: unset;
 }

 .additional-sharing-card {
   .ant-card-body {
     overflow: auto !important;
   }
 }

 .ant-checkbox .ant-checkbox-inner {
   border: 1px solid #b3b3b3;
 }

 /* .dashboard-tables{ */
 /* .ant-table-sticky-holder{
  top:125px !important
} */
 /* } */

 .addon-number-input {
   .ant-input-number-group {
     display: flex !important;

     .ant-input-number-group-addon {
       display: block;
       width: auto;
       padding: unset;
     }
   }
 }