.iconWraper {
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 3rem;

  .dowload-app-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    color: black;
    border: 1px solid #343e47;
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    width: 100%;
    text-align: center;
    transition-duration: 300ms;

    &:hover {
      background-color: #c9d9ec;
    }

    .iconText {
      display: flex;
      flex-direction: column;
      width: 160px;

      .ant-typography {
        text-align: start;
      }
    }

    .icon {
      font-size: 3rem;
      color: #465f7b;
    }
  }
}