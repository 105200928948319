.columnTitle {
  display: none;

  @media screen and (max-width: 560px) {
    display: flex;
    justify-content: center;
  }
}

.largeScreenTitle {
  /* display: block; */
  @media screen and (max-width: 560px) {
    display: none;
  }
}

.ant-typography {
  color: #727880; /* Use CSS variables for dynamic theming */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600; /* You cannot use props here, so set a default or use variables */
  margin-bottom: 0.5rem;
  line-height: 1.125rem;
}

.ant-typography .styled-drop-down-star {
  color: #727880; /* Use a CSS variable for the color */
  font-size: 1rem;
}

.radio-button {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 2rem;
}

.market-title {
  margin-right: 0.5rem;

  @media (max-width: 1200px) {
    margin-right: 0rem;
  }
}
