@import "./pages/Users/Accounts/User.module.css";
@import "./pages/Auth/auth.css";
@import "./styles/common.css";
@import "./styles/override.css";
@import "./pages/SummaryReport/summaryReport.css";
.scroll-to-top-button {
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 50px;
}
.sticky-main-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 999;
}
