.date-picker {
  @media (max-width: 768px) {
    width: 100vw; /* Ensure the dropdown fits the entire viewport width */
    left: 0 !important; /* Align dropdown to the left */
    right: 0 !important; /* Align dropdown to the right */
  }
  .ant-picker-datetime-panel {
    @media (max-width: 500px) {
      width: 90vw;
      overflow-x: auto;
      font-size: x-small;
    }
  }
}
