.common-label {
  color: #727880;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0.5rem;
  line-height: 1.125rem;
  display: block;
}

.block-script-table .ant-table-cell {
  padding: 5px !important;
}
.verification-modal {
  width: 50% !important;

  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
}

.v-h-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-to-top-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.w-100 {
  width: 100%;
}
